function fadeOut(selector) {
  if (document.querySelector(selector)) {
    var s = document.querySelector(selector).style;
    s.opacity = 1;
    (function fade(){(s.opacity-=.1)<0?s.display="none":setTimeout(fade,40)})();
  }
}

function displayingSpinner() {
  const spinner = document.querySelector(".spinner-box");
  spinner.insertAdjacentHTML('afterbegin', '<div class="spinner"></div>');
  spinner.classList.add("fade");
}

function desactivateSubmit(){
  if ( document.getElementById("subscription-form") || document.getElementById("new_user") ) {
    document.forms[0].addEventListener("submit", (event) => {
      document.getElementById("subscribeButton").disabled = true;
      [".flex-banner-content", "h3", "h4", "h6", ".recommendation-box", ".bottom-links", ".footer-banner", ".recommendation-wrapper"].forEach((selector) => {
        fadeOut(selector);
      });
      displayingSpinner();
    });
  }
}

export { desactivateSubmit }

function desactivateExpandButton(){
  if ( document.querySelector(".expand-form") ) {
    const expandForms = document.querySelectorAll(".expand-form");
    expandForms.forEach((expandForm) => {
      expandForm.addEventListener("submit", (event) => {
        const expandButton = expandForm.querySelector(".expand-button");
        expandButton.disabled = true;
        expandButton.innerText = "Loading...";
      });
    });
  }
}

export { desactivateExpandButton }

function updateCover(manga) {
  manga.querySelector(".scan-button").click();
  const mangas = document.querySelectorAll(`#${manga.parentNode.id}`);
  mangas.forEach((manga) => {
    manga.querySelector(".gradient").classList.toggle("hidden");
    manga.querySelector(".icon.follow-icon").classList.toggle("hidden");
  });
}

function updateSubBanner() {
  const subBanner = document.getElementById('sub-banner');
  if ( subBanner.querySelector(".validation-form").classList.contains('hidden') ) {
    subBanner.querySelector(".icon.follow-icon").classList.toggle("hidden");
    subBanner.querySelector(".add-mangas-caption").classList.toggle("hidden");
    subBanner.querySelector(".validation-form").classList.toggle('hidden');
  }
}

function updateCounter(manga) {
  const gradient = manga.querySelector('.gradient');
  if ( gradient.classList.contains('hidden') ) {
    const num = ( parseInt(document.getElementById("counter").innerHTML, 10) + 1 ).toString();
    document.getElementById("counter").innerHTML = num;
  } else {
    const num = ( parseInt(document.getElementById("counter").innerHTML, 10) - 1 ).toString();
    document.getElementById("counter").innerHTML = num;
  }
}

function alertCreation (){
  const mangas = document.querySelectorAll(".alert-form");
  mangas.forEach((manga) => {
    if ( manga.querySelector(".picture-container") ) {
      manga.querySelector(".picture-container").addEventListener("click", (event) => {
        updateCover(manga);
        updateSubBanner();
        updateCounter(manga);
      })
    }
  });
}

export { alertCreation }

function autoSubmission (){
  if (document.querySelector(".inline-form")) {
    const form = document.querySelector(".inline-form");
    var typingTimer
    var doneTypingInterval = 500;
    form.addEventListener("input", (event) => {
      form.addEventListener("keyup", (event) => {
        if ( (document.querySelector(".form-control").value == "") && (document.querySelector(".category-title") == null) ) {
          clearTimeout(typingTimer);
          typingTimer = setTimeout(formSubmission, doneTypingInterval);
        }
      })
    });
  }
}

export { autoSubmission }

function cleanSearchResults (){
  var elementExists = document.getElementById("results");
  if ( elementExists ) {
    var previousResultsTitle = elementExists.previousElementSibling;
    previousResultsTitle.parentNode.removeChild(previousResultsTitle);
    elementExists.parentNode.removeChild(elementExists);
  }
}

function loadingSpinner () {
  if ( document.querySelector(".inline-form") ) {
    document.querySelector(".inline-form").addEventListener("submit", (event) => {
    document.querySelector(".inline-form-button").disabled = true
    cleanSearchResults();
    cleaningPartial('<div class="spinner-box" style="position:relative;"><div class="spinner"></div><p class="loading-sentence">Loading...</p></div>');
    document.getElementById("sub-banner").style.display = "none";
    });
  }
}

export { loadingSpinner }

function formSubmission () {
  const button = document.querySelector(".inline-form-button");
  button.click();
}

function cleaningPartial (innerHTML) {
  var partial = document.getElementById("search-results");
  partial.insertAdjacentHTML("afterbegin", innerHTML);
}
