function closingModal(){
  if ( document.querySelector(".modal-background") ) {
    // Closing modal when user clicks on the close button
    document.querySelector(".modal-close").addEventListener("click", (event) => {
      var modal = document.querySelector(".modal-background");
      modal.parentNode.removeChild(modal);
    });
    // Closing modal when user clicks outside
    document.querySelector(".modal-background").addEventListener("click", (event) => {
      if (!event.target.matches('.modal-background')) return;
      var modal = document.querySelector(".modal-background");
      modal.parentNode.removeChild(modal);
    });
    // Closing model when user presses escape key
    document.addEventListener("keyup", (event) => {
      if(event.key === "Escape") {
        var modal = document.querySelector(".modal-background");
        modal.parentNode.removeChild(modal);
      }
    });
  }
}

export { closingModal }
